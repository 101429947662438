<template>
  <div>
    <el-table
      :data="riders"
      v-loading="isLoading"
      v-el-table-infinite-scroll="loadNewData"
      style="width: 100%"
      @cell-click="clickCell">
      <el-table-column label="Нэр">
        <template slot-scope="scope">
            <span>{{ scope.row.Username }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Бүртгүүлсэн огноо">
        <template slot-scope="scope">
          <span>{{scope.row.UserCreateDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Хэрэглэгчийн статус">
        <template slot-scope="scope">
          <!-- <span>{{scope.row.UserStatus}}</span> -->
          <div v-if="scope.row.UserStatus === 'CONFIRMED'">
          <el-tag type="success">баталгаажсан</el-tag>
          </div>
          <div v-else-if="scope.row.UserStatus === 'UNCONFIRMED' || scope.row.UserStatus === 'FORCE_CHANGE_PASSWORD'">
          <el-button size="small" type="primary" @click="confirmSignUp(scope.row)">Баталгаажуулах</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="И-мэйл">
        <template slot-scope="scope">
          <span>{{scope.row.email}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import supplierEditDialog from './supplierEdit'
import services from '../../../../helpers/services'
export default {
  props: [
    'riders',
    'getRiderUser',
    'loadNewData'
  ],
  data () {
    return {
      count: 10,
      isLoading: false,
      loading: false
    }
  },
  methods: {
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    confirmSignUp (data) {
      services.confirmSignUp({ user_name: data.Username }).then(response => {
        if (response.status === 'success') {
          this.bitrixSignUpRequest(data)
          this.getRiderUser()
          this.alertReporter('Амжилттай', 'Амжилттай баталгаажсан.', 'success')
        } else {
          this.alertReporter('Амжилттай', response.message, 'error')
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },
    async bitrixSignUpRequest (data) {
      try {
        const axios = require('axios')
        const body = {
          fields: {
            NAME: data.Username,
            OPENED: 'Y',
            TYPE_ID: 'CLIENT',
            UF_CRM_TOKTOK_TYPE: 'TOKTOKMALL-RIDER',
            PHOTO: {
              fileData: ''
            },
            PHONE: [
              {
                VALUE: data.phone_number.split('+976')[1],
                VALUE_TYPE: 'HOME'
              }
            ],
            EMAIL: [
              {
                VALUE: data.email,
                VALUE_TYPE: 'HOME'
              }
            ]
          }
        }
        const config = {
          method: 'post',
          url: 'https://toktok.bitrix24.com/rest/1/8kahn13m7r0rtb4s/crm.contact.add',
          data: body,
          headers: {
            Cookie: 'BITRIX_SM_SALE_UID=7; qmb=.'
          }
        }

        await axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data))
          })
          .catch(function (error) {
            console.log(error)
          })
      } catch (err) {
        console.log(err)
      }
    },
    clickCell (row, column, cell, event) {
      if (column.label !== 'Хэрэглэгчийн статус') {
        this.$router.push(
          {
            name: 'editRider',
            params: {
              user_name: row.Username
            }
          }
        )
      }
    }
  }
}
</script>
